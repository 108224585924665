import React from "react";
import theme from "theme";
import { Theme, Link, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaRegQuestionCircle, FaCcStripe, FaShieldAlt, FaChartBar, FaChartLine, FaBlackTie, FaBookOpen, FaChartPie, FaWpforms, FaFileDownload, FaHandPointRight, FaExternalLinkAlt } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"payment"} />
		<Helmet>
			<title>
				Profilozó Személyiségteszt | Sziklay Bence
			</title>
			<meta name={"description"} content={"Értsd meg önmagad egy Enneagram alapú személyiségteszttel! Lépj az önfelfedezés útjára most!"} />
			<meta property={"og:title"} content={"Profilozó Személyiségteszt | Sziklay Bence"} />
			<meta property={"og:description"} content={"Értsd meg önmagad egy Enneagram alapú személyiségteszttel! Lépj az önfelfedezés útjára most!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/creativeOG.webp?v=2024-08-06T08:29:54.434Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
		</Helmet>
		<Components.NavBar>
			<Override slot="linkContact" />
			<Override slot="link1" />
			<Override slot="quarklycommunityKitMenu" />
			<Override slot="image" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride1" />
			<Override slot="box" />
			<Override slot="box1" />
		</Components.NavBar>
		<Components.PaymentWrapper
			priceAddon3="6500"
			priceAddon2="3900"
			priceAddon1="6900"
			priceMax="18900"
			priceBase="3900"
			currencyCode="HUF"
			clientId="pk_live_51PfMeCHfRDDr64k8SzWi6HSQFN10QsUo3SRy0NMiOJOFquzxoiEOTPBmmEx4g4P601glTe8XBPVsW29HNJ7WvPes00hzE6tKgJ"
			itemDescription="sziklaybence.com"
			price="1"
			itemName="Személyiség Teszt"
			background="#f9f9f9 linear-gradient(0deg,--color-light 25.7%,#ffffff 100%)"
			padding="0px 0px 80px 0px"
			margin="50px 0px 0px 0px"
			sm-margin="30px 0px 0px 0px"
		>
			<Override
				slot="Content"
				grid-gap="50px"
				max-width="1200px"
				sm-grid-template-columns="repeat(1, 1fr)"
				grid-template-columns="3fr 2fr"
				display="grid"
				md-grid-template-columns="repeat(1, 1fr)"
				padding="0px 25px 0px 25px"
				sm-padding="0px 10px 0px 10px"
				justify-items="center"
				margin="0px auto 0px auto"
			/>
			<Override slot="PaymentBox" id="payment-box" lg-display="grid" />
			<Override
				slot="DownButton"
				margin="25px 0px 0px 0px"
				border-radius="5px"
				hover-transform="scale(1.1)"
				hover-transition="all 1s ease 0s"
				hover-opacity="0.8"
			>
				<div />
			</Override>
			<Override slot="ShortcutTextWrapper" justify-items="center" />
			<Override
				slot="CertGenBtn"
				hover-background="--color-sandyBrown"
				hover-transform="scale(1.05)"
				hover-transition="all 1s ease 0s"
				font="--lead"
			/>
			<Override slot="TitleWrapper" />
			<Override slot="AirTable Wrapper" margin="25px 0px 25px 0px" />
			<Override slot="Label" font="normal 300 15px/1.5 --fontFamily-googlePoppins" />
			<Override slot="loadingPG pay success wrapper" height="262px" />
			<Override slot="loader wrapper" height="262px" />
			<Override slot="NameTooltip" border-width={0} border-style="none">
				<Override slot="Tooltip" />
			</Override>
			<Override
				slot="PayTitleIcon"
				category="fa"
				color="--lightBlue"
				align-self="center"
				font="32px Poppins, sans-serif"
				icon={FaRegQuestionCircle}
			/>
			<Override slot="Title Wapper" display="flex" />
			<Override slot="TooltipIconWrapper" display="flex" />
			<Override slot="Payment Subtitle" font="italic 300 15px/25.5px Poppins, sans-serif" margin="16px 0px 16px 5px" />
			<Override slot="Payment Title" align-self="center" />
			<Override
				slot="CustomTooltip"
				tooltipContent="Provide your name for the certificate and email for test result access. No marketing emails will be sent. Data held for 90 days; email hello@iqresearches.com for earlier removal. Continue to payment via PayPal's secure gateway."
				border-style="none"
				interactionType="click"
				cursor="pointer"
				borderRadius="15px"
				border="1px solid #70B3FF "
				color="--primary"
				backgroundColor="rgba(241, 246, 249, 0.8)"
				padding="0 10px 0 10px"
				sm-position="relative"
				offsetLeft="-100"
			>
				<Override slot="Tooltip" />
			</Override>
			<Override slot="PaypalLink" color="--lightBlue" hover-color="--secondary" target="_blank" />
			<Override slot="Data Privacy" margin="16px 0px 32px 0px" font="normal 300 15px/1.5 --fontFamily-googleRubik">
				We don’t store payment details; transactions occur directly between your browser and PayPal, without the need for a registered PayPal account. See PayPal’s{" "}
				<Link
					href="https://www.paypal.com/us/legalhub/privacy-full"
					color="--lightBlue"
					hover-color="--secondary"
					target="_blank"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Privacy Statement
				</Link>
				{" "}for more details.
			</Override>
			<Override
				slot="PaymentFooterIcon1"
				color="--grey"
				cursor="pointer"
				id="paypalico"
				className="pficos"
				category="fa"
				icon={FaCcStripe}
			/>
			<Override
				slot="PaymentFooterIcon2"
				color="--grey"
				cursor="pointer"
				category="fa"
				icon={FaShieldAlt}
				id="secureico"
				className="pficos"
			/>
			<Override
				slot="PaymentFooterIcon3"
				color="--grey"
				cursor="pointer"
				id="dataico"
				className="pficos"
			/>
			<Override slot="PaymentFooterWrapper" position="relative" z-index="100" />
			<Override
				slot="CustomTooltipIcon1"
				border-style="none"
				interactionType="click"
				tooltipContent="Your payment security is our priority. Transactions are secured through PayPal. Learn more about PayPal's safety measures: https://www.paypal.com/uk/webapps/mpp/paypal-safety-and-security"
				backgroundColor="rgba(241, 246, 249, 0.8)"
				padding="0 10px 0 10px"
				border="1px solid #70B3FF "
				borderRadius="15px"
				font="--base"
				text-align="left"
				popupContent=" A fizetési biztonság a legfontosabb számunkra. A tranzakciók a Stripe segítségével kerülnek biztosításra. További információt a Stripe biztonsági intézkedéseiről itt találhatsz: https://stripe.com"
				maxWidth="360px"
			/>
			<Override
				slot="CustomTooltipIcon2"
				border-style="none"
				interactionType="click"
				tooltipContent="We use Cloudflare to enhance our website's security, ensuring a fast, secure, and seamless experience. Cloudflare's global network offers advanced protection against DDoS attacks and provides unique threat intelligence, making our site more reliable and secure for users. For more details on how Cloudflare secures our site, visit https://www.cloudflare.com/en-gb/security/ ."
				backgroundColor="rgba(241, 246, 249, 0.8)"
				padding="0 10px 0 10px"
				border="1px solid #70B3FF "
				borderRadius="15px"
				font="--base"
				text-align="left"
				offsetLeft="-150"
				popupContent=" A weboldalunk biztonságának növelése érdekében a Cloudflare-t használjuk, ami gyors, biztonságos és zökkenőmentes élményt biztosít. A Cloudflare globális hálózata fejlett védelmet nyújt a DDoS támadások ellen, és egyedülálló fenyegetés-elhárítást biztosít, így megbízhatóbbá és biztonságosabbá teszi weboldalunkat a felhasználók számára. További részletekért arról, hogyan védi a Cloudflare weboldalunkat, látogass el ide: https://www.cloudflare.com/en-gb/security/."
				maxWidth="360px"
			/>
			<Override
				slot="CustomTooltipIcon3"
				border-style="none"
				interactionType="click"
				tooltipContent="We prioritise data security and privacy, not storing passwords or personal details. Only emails are kept for 90 days to facilitate result retrieval, securely stored in our cloud database, ensuring your information is protected and accessible only to you."
				backgroundColor="rgba(241, 246, 249, 0.8)"
				padding="0 10px 0 10px"
				border="1px solid #70B3FF "
				borderRadius="15px"
				font="--base"
				text-align="left"
				offsetLeft="-300"
				popupContent="Fontos számunkra az adatok biztonsága és védelme, így nem tárolunk jelszavakat vagy személyes adatokat. "
				maxWidth="360px"
			/>
			<Override slot="ReactTooltip" popupContent="Provide your name for the certificate and email for test result access. No marketing emails will be sent. Data held for 90 days; email hello@iqresearches.com for earlier removal. Continue to payment via PayPal's secure gateway." margin="0px 0px 0px 15px" maxWidth="360px" />
			<Override
				slot="PricePanel"
				border-color="none"
				border-width="none"
				background="none"
				border-style="none"
			>
				<Override slot="TotalPriceInt" font="600 48px/76.8px --fontFamily-googleRubik" margin="0 0 0 0">
					£6
				</Override>
				<Override slot="TotalPriceDecimals" opacity="0" position="absolute" />
				<Override
					slot="TotalCurrencySymbol"
					font="200 48px/76.8px --fontFamily-googleRubik"
					border-color="--color-primary"
					text-align="left"
					margin="0 0 0 0"
				/>
				<Override slot="text9" font="normal 300 15px/1.5 --fontFamily-googleRubik">
					Letölthető PDF
				</Override>
				<Override slot="text8" font="normal 400 17px/1.5 --fontFamily-googleRubik" text-align="left">
					+ Részletes karakteranalízis és fejlődési pálya
				</Override>
				<Override slot="text11" font="normal 400 17px/1.5 --fontFamily-googleRubik" text-align="left" color="--light">
					+ Az enneagram rendszere, karakterek és fejlődés
				</Override>
				<Override slot="text14" font="normal 400 17px/1.5 --fontFamily-googleRubik" text-align="left">
					+ Profilozói kézikönyv
				</Override>
				<Override slot="text5" font="normal 400 18px/1.5 --fontFamily-googleRubik" text-align="left">
					Enneagram karaktertípus és színezet
				</Override>
				<Override slot="text6" font="normal 300 15px/1.5 --fontFamily-googleRubik" text-align="right">
					Az Alapcsomag tartalmazza a Karakteranalízist, letölthető PDF formátumban.
				</Override>
				<Override slot="text12" font="normal 300 15px/1.5 --fontFamily-googleRubik" color="--light">
					Profilozói Keretrendszer - Letölthető PDF
				</Override>
				<Override slot="text15" font="normal 300 15px/1.5 --fontFamily-googleRubik">
					Letölthető PDF, webshopunkban is megvásárolható
				</Override>
				<Override slot="text16" font="--base" padding="0 15px 0 15px">
					Válaszd ki a tetszőleges kiegészítőket, hogy a lehető legtöbbet hozd ki a személyiségteszt eredményeiből!
				</Override>
				<Override slot="SideLabel" right="-66px" margin="16px 8px 16px 0px">
					sziklaybence.com
				</Override>
				<Override slot="box5" display="none" />
				<Override slot="icon" />
				<Override slot="text10" font="200 15px/20.5px Rubik, sans-serif" />
				<Override slot="text13" font="200 15px/20.5px Rubik, sans-serif" />
				<Override slot="text7" font="200 15px/20.5px Rubik, sans-serif" />
				<Override slot="box9" margin="0px 0 0px 15px" />
				<Override slot="box12" margin="0px 0 0px 15px" />
				<Override slot="box15" margin="0px 0 0px 15px" />
				<Override slot="box6" />
				<Override slot="Get-Basics" padding="5px 0px 5px 15px" />
				<Override slot="Get-Addon2" />
			</Override>
			<Override slot="Addon1Title" font="--headline2" color="--primary">
				Eredmény
			</Override>
			<Override slot="Addon2Title" font="--headline2" color="--primary">
				Addon 2 Title
			</Override>
			<Override slot="Addon3Title" font="--headline2" color="--primary">
				Addon 3 Title
			</Override>
			<Override slot="Addon1 Text">
				+ Addon 1 részletek...
			</Override>
			<Override slot="DisclaimerBottomText" font="normal 300 15px/1.5 --fontFamily-googleRubik" padding="16px 16px 16px 16px" display="none">
				Fontos számunkra az adataid védelme. A személyes adataid biztonságát szem előtt tartjuk, és minden tranzakció a PayPal biztonságos fizetési rendszerén keresztül történik. Biztos lehetsz benne, hogy nincsenek rejtett díjak vagy előfizetések; ez egy egyszeri fizetés a teszteredményeidért és a kiválasztott kiegészítőkért. Az általad megadott információkat bizalmasan kezeljük. Vásárlásoddal elfogadod, hogy az eredményeket és a kiegészítőket az általad megadott email címre küldjük el.
			</Override>
			<Override slot="SecurePaymentExplainer" font="normal 300 15px/1.5 --fontFamily-googleRubik" padding="16px 16px 16px 16px" display="none">
				A személyiségteszt eredményei tájékoztató jellegűek, és nem minősülnek szakmai tanácsadásnak vagy diagnózisnak. A teszt és a kapcsolódó kiegészítők használata saját felelősségre történik. Az eredmények pontosságáért és a teszt alkalmazásának következményeiért nem vállalunk felelősséget. Amennyiben személyes vagy szakmai problémával küzdesz, javasoljuk, hogy fordulj megfelelő szakemberhez.
			</Override>
			<Override slot="Analimage" src="https://uploads.quarkly.io/6602d5961d712a0023322e52/images/paid.webp?v=2024-06-18T18:26:17.254Z" />
			<Override
				slot="BrainIco"
				position="absolute"
				top="10px"
				width="40px"
				height="40px"
				right="14px"
				opacity="0.8"
			/>
			<Override
				slot="Main Result Title"
				font="normal 400 2.5em/1.2 --fontFamily-googleLexend"
				color="--primary"
				as="h1"
				text-align="center"
			>
				Eredmény*
			</Override>
			<Override
				slot="Download List"
				as="ul"
				list-style-type="none"
				display="grid"
				grid-gap="16px"
			/>
			<Override
				slot="Result Wrapper"
				max-width="500px"
				display="grid"
				background="#ffffff"
				border-radius="15px"
				box-shadow="--xl"
				margin="0px auto 0px auto"
				justify-content="center"
				padding="16px 16px 16px 16px"
			/>
			<Override slot="Download Wrapper" />
			<Override slot="PTypeValue" text-align="center" color="--secondary" font="normal 600 26px/1.5 --fontFamily-googleRubik" />
			<Override
				slot="PType"
				font="normal 900 28px/1.2 --fontFamily-googleLexend"
				text-align="center"
				color="--primary"
				opacity="0.8"
				margin="16px 0px 0 0px"
			/>
			<Override
				slot="PWing"
				font="--headline2"
				text-align="center"
				color="--primary"
				opacity="0.8"
				margin="16px 0px 0 0px"
			/>
			<Override slot="PWingValue" text-align="center" font="normal 600 26px/1.5 --fontFamily-googleRubik" color="--amaranth" />
			<Override slot="SectionContent" />
			<Override
				slot="WingWrapper"
				border-width="1px 0 0 0"
				border-style="solid"
				border-color="--color-light"
				padding="8px 0px 0px 0px"
			/>
			<Override slot="TypeWrapper" padding="0px 0px 8px 0px" />
			<Override
				slot="List Wrapper"
				max-width="500px"
				padding="16px 16px 16px 16px"
				box-shadow="--xl"
				background="#ffffff"
				border-radius="15px"
				display="grid"
				grid-template-columns="1fr 6fr"
				margin="0px auto 0px auto"
				align-items="center"
				justify-items="center"
				sm-grid-template-columns="1fr"
			/>
			<Override
				slot="Basic Ico"
				category="fa"
				icon={FaChartBar}
				margin="0px 10px 0px 0px"
				color="--grey"
			/>
			<Override slot="Addon1" display="flex" align-items="center" />
			<Override slot="Addon2" display="flex" align-items="center" />
			<Override slot="Addon3" display="flex" align-items="center" />
			<Override slot="Basic" display="flex" align-items="center" />
			<Override
				slot="Addon1 Ico"
				category="fa"
				icon={FaChartLine}
				margin="0px 10px 0px 0px"
				color="--grey"
			/>
			<Override
				slot="Addon2 Ico"
				category="fa"
				icon={FaBlackTie}
				margin="0px 10px 0px 0px"
				color="--grey"
			/>
			<Override
				slot="Addon3 Ico"
				category="fa"
				icon={FaBookOpen}
				margin="0px 10px 0px 0px"
				color="--grey"
			/>
			<Override
				slot="Additional Info"
				text-align="center"
				font="--base"
				color="--primary"
				max-width="600px"
				margin="16px auto 16px auto"
			>
				A teszt eredményét elküldtük emailcímedre is.
			</Override>
			<Override
				slot="Review Disclaimer"
				font="italic normal 300 15px/1.5 --fontFamily-googleRubik"
				color="--primary"
				margin="16px auto 16px auto"
				max-width="600px"
				padding="16px 0px 0px 0px"
				border-width="1px 0 0 0"
				border-style="solid"
				border-color="#ffffff"
			>
				*Néhány teszteredmény manuális átvizsgálást igényel. Ilyen esetben 5 munkanapon belül manuálisan is átnézzük és visszajelzünk a kivizsgálás eredményéről.
			</Override>
			<Override slot="icon1" category="fa" icon={FaChartPie} />
			<Override
				slot="MainTitle"
				font="--headline2"
				color="--amaranth"
				padding="0px 16px 0px 16px"
				sm-padding="0px 0 0px 0"
				md-padding="0px 50px 0px 50px"
			>
				Válaszd ki, milyen szintű elemzést kérsz!{" "}
			</Override>
			<Override slot="SideLabel" margin="16px -19px 16px 0px" />
			<Override
				slot="TotalPriceWrapper"
				flex-direction="row"
				display="flex"
				align-items="center"
				justify-content="center"
				z-index="10"
				left={0}
				right={0}
				border-color="#16263F"
			/>
			<Override slot="box3" display="flex" font="normal 600 26px/1.5 --fontFamily-googleRubik" />
			<Override slot="TotalPriceDecimals" display="none" />
			<Override slot="text2" display="none" />
			<Override
				slot="PPInnerWrapper"
				padding="1px 0px 0px 0px"
				border-width="1px"
				border-color="--color-secondary"
				border-style="solid"
				border-radius="24px"
			/>
			<Override slot="TotalCurrencySymbol" font="normal 400 26px/1.5 --fontFamily-googleRubik" margin="0 0px 0 0px" />
			<Override
				slot="box5"
				display="flex"
				justify-content="center"
				align-items="center"
				opacity="0.5"
			/>
			<Override slot="text5" font="--base" margin="0 0px 0 0px">
				végösszeg
			</Override>
			<Override slot="text6" text-align="left" font="--base" border-color="--color-primary">
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Az alapcsomag
				</Strong>
				{" "}tartalmazza a karaktertípusodat és személyiségleírásodat.
				<br />
				Letölthető PDF (11 oldal)
			</Override>
			<Override
				slot="Addon2 Wrapper"
				display="flex"
				color="--light"
				border-width="1px 0 1px 0"
				border-style="solid"
				height="150px"
				position="relative"
			/>
			<Override
				slot="Addon1 Wrapper"
				display="flex"
				color="--light"
				border-width="1px 0 1px 0"
				border-style="solid"
				height="150px"
				position="relative"
			/>
			<Override
				slot="Addon3 Wrapper"
				display="flex"
				color="--light"
				border-width="1px 0 0 0"
				border-style="solid"
				height="150px"
				position="relative"
			/>
			<Override slot="text8" font="--lead">
				+ Részletes karakteranalízis és fejlődési pálya
			</Override>
			<Override slot="text9" font="--base">
				Letölthető PDF (17+ oldal)
			</Override>
			<Override slot="text11" font="--lead">
				+ Részletes leírás az összes személyiségtípusról
			</Override>
			<Override slot="text12" font="--base">
				Profilozói Keretrendszer - Letölthető PDF (127 oldal)
			</Override>
			<Override slot="text14" font="--lead">
				+ Tanulj meg úgy profilozni, mint egy FBI Ügynök!
			</Override>
			<Override slot="text15" font="--base">
				Letölthető PDF, webshopunkban is megvásárolható
			</Override>
			<Override slot="Get-Addon1" display="flex" background="--color-secondary" width="100%" />
			<Override slot="box8" display="none" justify-content="center" align-items="flex-end" />
			<Override slot="button" focus-box-shadow="none" />
			<Override slot="text7" margin="0 0px 0 0px" align-items="center" justify-content="center" />
			<Override slot="button2" focus-box-shadow="none" />
			<Override slot="button1" focus-box-shadow="none" />
			<Override slot="PPFooter" display="grid" grid-template-columns="3fr 1fr 1fr" padding="0px 0px 0px 16px" />
			<Override slot="box12" text-align="left" margin="0px 0px 0px 16px" max-width="300px" />
			<Override slot="box15" text-align="left" margin="0px 0px 0px 16px" max-width="300px" />
			<Override slot="box9" text-align="left" margin="0px 0px 0px 16px" max-width="300px" />
			<Override slot="TotalPriceInt" margin="0 0px 0 0px" />
			<Override slot="Get-Basics" display="grid" grid-template-columns="6fr 2fr" margin="65px 0px 0px 0px" />
			<Override slot="box6" padding="0px 0 0px 16px" />
			<Override slot="icon" category="fa" icon={FaWpforms} size="64px" />
			<Override slot="box11" display="none" />
			<Override slot="box14" display="none" />
			<Override slot="Get-Addon2" width="100%" background="--color-secondary" />
			<Override slot="Get-Addon3" width="100%" background="--color-secondary" />
			<Override slot="Total Price Anim" z-index="3" />
			<Override
				slot="VideoDemo"
				grid-column="1/3"
				md-grid-column="auto"
				padding="30px 0 0 0"
				border-radius="15px"
			>
				<Override slot="box1" />
				<Override slot="SectionContent" />
				<Override slot="box" padding="16px 16px 16px 16px" id="demovideo" />
				<Override slot="quarklycommunityKitYouTube" font-style modestbranding url="https://youtu.be/f6xtbOMf6cA" />
				<Override slot="text1">
					Nézd meg a videót, amely részletesen bemutatja, milyen élmény vár rád a vásárlás után! A személyiségteszt kitöltése és értékelése után egy átfogó, személyre szabott elemzést kapsz, amely mélyreható betekintést nyújt karaktered rejtett oldalaira. Fedezd fel belső motivációidat, félelmeidet és erősségeidet, és tudd meg, hogyan kommunikálhatsz hatékonyabban különböző személyiségtípusokkal.
				</Override>
			</Override>
			<Override slot="McSubscribeCheckbox" display="flex">
				<Override slot="MC Label" padding="0px 0px 0px 16px" font="--base">
					Feliratkozom a hírlevélre.
				</Override>
			</Override>
			<Override
				slot="Grouping"
				margin="30px 0px 0px 0px"
				id="payhere"
				border-radius="15px"
				border-width="1px"
				border-style="solid"
				border-color="--color-secondary"
				padding="0 15px 15px 15px"
			/>
			<Override slot="text16" justify-self="center" font="normal 400 18px/1.5 --fontFamily-googleRubik" align-self="center">
				Nézd meg mit kapsz vásárlás után
			</Override>
			<Override
				slot="DemoVideoBtn"
				justify-self="end"
				border-radius="0 0 22px 0"
				background="--color-primary"
				type="button"
			>
				Bemutató Videó
			</Override>
			<Override
				slot="Download Ico"
				category="fa"
				icon={FaFileDownload}
				font="64px"
				size="64px"
			/>
			<Override
				slot="demobuttonIco"
				category="fa"
				icon={FaHandPointRight}
				justify-self="center"
				align-self="center"
				color="--primary"
				opacity="0.8"
			/>
			<Override slot="Title" margin="30px 0px 30px 0px" text-align="center" font="--lead" />
			<Override
				slot="GottaPay"
				position="fixed"
				width="100%"
				display="grid"
				align-items="center"
				justify-items="center"
				z-index="100000"
				color="--light"
				font="--lead"
				box-shadow="--xl"
				bottom={0}
				opacity="0.9"
				cursor="pointer"
			/>
			<Override
				slot="GPCWrapper"
				background="--color-light"
				id="gotopay"
				font="--base"
				margin="16px 0px 0 0px"
				border-radius="15px 15px 0 0"
				border-width="2px 2px 0 2px"
				border-style="solid"
				border-color="--color-amaranth"
				color="--amaranth"
				display="flex"
				padding="0px 16px 0px 16px"
			/>
			<Override slot="GottaPayLabel" align-self="center" padding="0px 16px 0px 0px" font="normal 400 17px/1.5 --fontFamily-googleRubik">
				Az eredményt fizetés után mutatjuk{" "}
			</Override>
			<Override slot="GPIcon" category="fa" icon={FaExternalLinkAlt} align-self="center" />
			<Override
				slot="PaymentTitle"
				font="normal 600 28px/1.2 --fontFamily-googleLexend"
				as="h2"
				color="--primary"
				text-align="center"
				opacity="0.8"
			>
				Fizetés itt
			</Override>
			<Override slot="Box1-Text">
				Fontos számunkra az adataid védelme. A személyes adataid biztonságát szem előtt tartjuk, és minden tranzakció a PayPal biztonságos fizetési rendszerén keresztül történik. Biztos lehetsz benne, hogy nincsenek rejtett díjak vagy előfizetések; ez egy egyszeri fizetés a teszteredményeidért és a kiválasztott kiegészítőkért. Az általad megadott információkat bizalmasan kezeljük. Vásárlásoddal elfogadod, hogy az eredményeket és a kiegészítőket az általad megadott email címre küldjük el.
			</Override>
			<Override
				slot="Box1-Wrapper"
				display="grid"
				padding="16px 16px 16px 16px"
				border-radius="15px"
				box-shadow="--xl"
			/>
			<Override slot="Box2-Text">
				A személyiségteszt eredményei tájékoztató jellegűek, és nem minősülnek szakmai tanácsadásnak vagy diagnózisnak. A teszt és a kapcsolódó kiegészítők használata saját felelősségre történik. Az eredmények pontosságáért és a teszt alkalmazásának következményeiért nem vállalunk felelősséget. Amennyiben személyes vagy szakmai problémával küzdesz, javasoljuk, hogy fordulj megfelelő szakemberhez.
			</Override>
			<Override slot="Box3s-Text">
				Elkötelezzük magunkat amellett, hogy az általad megadott adatokat a GDPR szerint érzékeny adatként kezeljük. Ez azt jelenti, hogy minden adatot a törvényi előrírásoknak megfelelő biztonsággal tárolunk, és csak az arra feljogosított személyzet férhet hozzájuk. Rendszeresen ellenőrizzük és frissítjük biztonsági protokolljainkat, hogy a legmagasabb szintű védelmet nyújthassuk az adataidnak. Továbbá, ha bármilyen adatvédelmi incidens történne, azonnal értesítünk téged, és minden szükséges lépést megteszünk a helyzet kezelésére és a károk minimalizálására.

Bízhatsz bennünk, hogy adataidat a legnagyobb gondossággal és szakértői tudással kezeljük, hogy biztosíthassuk azok biztonságát és a te nyugalmadat.
			</Override>
			<Override slot="Disclaimer Section" background="--color-light">
				<Override slot="SectionContent" display="grid" grid-template-columns="repeat(2, 1fr)" />
			</Override>
			<Override
				slot="DisclaimerSection"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				max-width="1200px"
				margin="80px auto 0px auto"
				font="--base"
				grid-gap="16px 30px"
				text-align="justify"
				sm-grid-template-columns="1fr"
				padding="0px 16px 0px 16px"
			>
				<Override slot="SectionContent" />
			</Override>
			<Override
				slot="Box3-Wrapper"
				grid-column="1/3"
				max-width="600px"
				margin="0px auto 0px auto"
				padding="16px 16px 16px 16px"
				border-radius="15px"
				box-shadow="--xl"
				sm-grid-column="auto"
			/>
			<Override slot="Box2-Wrapper" padding="16px 16px 16px 16px" border-radius="15px" box-shadow="--xl" />
			<Override slot="PayPal">
				<Override slot="NameInput" display="grid" grid-template-columns="1fr 1fr" grid-gap="12px" />
				<Override slot="FirstNameInput" width="100%" />
				<Override slot="LastNameInput" width="100%" />
				<Override slot="SubmitButton" background="--color-amaranth" font="--lead" border-radius="15px" />
				<Override slot="StripeButtonWrapper" />
				<Override
					slot="overlayer"
					left={0}
					right={0}
					top={0}
					bottom={0}
					position="absolute"
					z-index="10"
				/>
				<Override slot="Form" position="relative" />
				<Override slot="FormWrapper" position="relative" />
			</Override>
			<Override slot="ShareButtons">
				<Override slot="ButtonsWrapper" display="flex" justify-content="center" align-items="center" />
			</Override>
		</Components.PaymentWrapper>
		<Components.Footer>
			<Override slot="text1" />
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});